import React, { useState, useMemo } from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Input, Button, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, Formspree, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";

import axios from 'axios';

import { useLoginState } from './userStateContext';

import localFavicon from '../resources/Icons/logo_32x32.ico';
import SOMALogo from '../resources/Logos/Logo.jpeg';

export default (() => {

	const { loginState, updateLoginState } = useLoginState(false);
	// const [formSaved, setFormSaved] = useState(false);
	// const [clearForm, setClearForm] = useState(false);
	// const { firstNameState, updateFirstNameState } = useLoginState('');
	// const { lastNameState, updateLastNameState } = useLoginState('');
	// const { emailState, updateEmailState } = useLoginState('');
	// const { phNumberState, updatePhNumberState } = useLoginState('');
	// const { instituteNameState, updateInstituteNameState } = useLoginState('');
	// const { designationState, updateDesignationState } = useLoginState('');
	// const { messageState, updateMessageState } = useLoginState('');

	const [jsonData, setJsonData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phNumber: '',
		instituteName: '',
		designation: '',
		message: '',
	});  

	const handleInputChange = (event) => {
		const newValue = event.target.value; // Get the new value from the input
		const eventTrigger = event.target.name;
		switch(eventTrigger) {
			case "firstName" : setJsonData({ ...jsonData, firstName: newValue });
								break;
			case "lastName" : setJsonData({ ...jsonData, lastName: newValue });
								break;
			case "email" : setJsonData({ ...jsonData, email: newValue });
								break;
			case "phNumber" : setJsonData({ ...jsonData, phNumber: newValue });
								break;
			case "instituteName" : setJsonData({ ...jsonData, instituteName: newValue });
								break;
			case "designation" : setJsonData({ ...jsonData, designation: newValue });
								break;
			case "message" : setJsonData({ ...jsonData, message: newValue });
								break;
		}
	};

	// Function to handle sending submitted form to respecive mails
  	const sendEmail = async (formDataToSend) => {
		try {
			const emailAdd = "NJ.SOMA.develop@gmail.com;editorinchiefsoma@gmail.com";
			const mailSubject = "Contact request from NJSOMA service";
			//const mailData = JSON.stringify(formDataToSend);

			//const formData = new URLSearchParams();

			// for (const key in formDataToSend) {
			// 	if (Object.hasOwnProperty.call(formDataToSend, key)) {
			// 		formData.append(key, jsonData[key]);
			// 	}
			// }
			const formattedData = Object.keys(jsonData)
				.map(key => `${key}: ${jsonData[key]}`)
				.join('\n')

			console.log(formattedData);
			const mailToLink = `mailto:${emailAdd}?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(formattedData)}`;
			window.location.href = mailToLink;
		} catch (error) {
		}
		// try {
		// 	// Send the data to the server
		// 	console.log("SendingEMail forward to server");
		// 	const response = await axios.post('./php_server/sendMail.php', formDataToSend, {
		// 		//headers: { 'Content-Type': 'multipart/form-data' },
		// 		method: 'POST',
		// 	});

		// 	if (response.ok) {
		// 		const result = await response.text();
		// 		console.log("email sent success");
		// 		console.log(result);
		// 	} else {
		// 		console.error('Email request failed at SOMA server');
		// 		const result = await response.text();
		// 		console.log(result);
		// 	}

		// 	// Show a success message
		// 	alert('Email Data uploaded successfully!');
		// } catch (error) {
		// 	console.error('Error:', error);
		// 	console.log("Error in email request detected");
		// 	alert('An error occurred while tring to send email. Please try again later.');
		// }
	};

	const handleSubmitForm = async () => {		
		console.log("Message to Editor submission.");
		console.log(JSON.stringify(jsonData));
		// const formDataToSend = new FormData();
		// formDataToSend.append('jsonData', JSON.stringify(jsonData));
		// console.log(formDataToSend.jsonData);
		sendEmail(jsonData);
	}

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Contact Us
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"Contact"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<link rel={"shortcut icon"} href={localFavicon} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Override slot="SectionContent" />
			<Box
				min-width="100px"
				min-height="100px"
				order="0"
				align-self="stretch"
				display="flex"
			>				
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						<Image
							src={SOMALogo}
							display="block"
							max-height="150px"
							max-width="150px"
							srcSet=""
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px" align-self="center">
					<Text margin="0px 0 24px 0" text-align="center" font="--headline2" md-font="--headline3">
						National Journal of Society of Medical Anatomists
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Input
						display="block"
						placeholder-color="LightGray"
						background="white"
						id="loginUser"
						className="LoginUser"
						defaultValue="User ID :"
						disabled
						opacity={0}/*{loginState ? 1 : 0}*/
					/>
					<Button opacity={0}/*{loginState ? 0 : 1}*/ id="loginRegBtn" className="LoginRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Login
					</Button>
					<Button opacity={0}/*{loginState ? 1 : 0}*/ id="logoutRegBtn" className="LogoutRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Logout
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Box min-width="100px" min-height="100px" />
			<Box>
				<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
					<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
					<Override
						slot="menu"
						md-top={0}
						md-width="100%"
						justify-content="center"
						lg-transition="transform 400ms ease 0s"
						md-position="fixed"
						display="flex"
						md-left={0}
						lg-transform="translateY(0px) translateX(0px)"
						md-height="100%"
						padding="0px 0 0px 0"
						align-items="center"
					>
						<Override
							slot="item"
							md-padding="16px 40px 16px 40px"
							display="inline-block"
							text-transform="uppercase"
							text-align="center"
							padding="8px 20px 8px 20px"
						/>
						<Override slot="item-404" lg-display="none" display="none" />
						<Override slot="item-index" lg-display="none" display="none" />
						<Override
							slot="link"
							md-hover-opacity="1"
							md-active-opacity="1"
							md-color="--dark"
							font="--base"
							text-transform="initial"
							md-font="16px/24px sans-serif"
							text-decoration-line="initial"
							color="--dark"
							transition="opacity .15s ease 0s"
							letter-spacing="0.5px"
							md-opacity=".5"
							md-transition="opacity .15s ease 0s"
							opacity=".5"
							hover-opacity="1"
						/>
						<Override
							slot="link-active"
							md-opacity="1"
							md-cursor="default"
							opacity="1"
							color="--primary"
							cursor="default"
						/>
					</Override>
					<Override slot="icon,icon-close" category="md" icon={MdMenu} />
					<Override
						slot="icon"
						category="md"
						icon={MdMenu}
						size="36px"
						md-right="0px"
						md-position="relative"
					/>
					<Override
						slot="menu-open"
						md-justify-content="center"
						md-top={0}
						md-bottom={0}
						md-display="flex"
						md-flex-direction="column"
						md-align-items="center"
					/>
				</Components.BurgerMenu>
			</Box>
		</Section>
		<Section
			justify-content="center"
			box-sizing="border-box"
			quarkly-title="Contacts"
			padding="50px 0px 50px 0px"
			lg-padding="25px 0px 25px 0px"
		>
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				display="grid"
				grid-gap="82px"
				grid-template-columns="7fr 3fr"
				md-grid-template-columns="1fr"
				md-grid-gap="40px"
				lg-grid-gap="36px"
				width="100%"
			>
				<Box
					border-style="solid"
					border-color="--color-lightD2"
					display="flex"
					flex-direction="column"
					background="--color-lightD1"
					padding="48px 36px 54px 36px"
					border-width="1px"
				>
					<Text
						color="--dark"
						md-font="--base"
						font="--base"
						margin="0px 0px 28px 0px"
						display="inline-block"
						quarkly-title="Description"
					>
						For any suggestions, complaints and compliments please contact us through the form below.
					</Text>
					<Formspree
						errorMessage="Something went wrong"
						completeText="Success"
						endpoint="meqveqwr"
						display="flex"
						width="100%"
					>
						<Input
							placeholder="First Name"
							width="66.66%"
							margin="0px 4px 10px 0px"
							type="text"
							md-width="100%"
							lg-width="100%"
							name="firstName"
							required={true}
							value={jsonData.firstName}
							onChange={handleInputChange}
							
						/>
						<Input
							placeholder="Last Name"
							width="66.66%"
							margin="0px 4px 10px 0px"
							type="text"
							md-width="100%"
							lg-width="100%"
							name="lastName"
							required={true}
							value={jsonData.lastName}
							onChange={handleInputChange}
						/>
						<Input
							name="email"
							placeholder="Email ID"
							width="66.66%"
							margin="0px 4px 10px 0px"
							type="email"
							md-width="100%"
							margin-right="4px"
							lg-width="100%"
							required={true}
							value={jsonData.email}
							onChange={handleInputChange}
						/>
						<Input
							margin="0px 4px 10px 0px"
							type="tel"
							md-width="100%"
							lg-width="100%"
							name="phNumber"
							placeholder="Phone No"
							width="66.66%"
							required={true}
							value={jsonData.phNumber}
							onChange={handleInputChange}
						/>
						<Input
							placeholder="Name of Institution"
							width="66.66%"
							margin="0px 4px 10px 0px"
							type="text"
							md-width="100%"
							lg-width="100%"
							name="instituteName"
							required={true}
							value={jsonData.instituteName}
							onChange={handleInputChange}
						/>
						<Input
							placeholder="Designation"
							width="66.66%"
							margin="0px 4px 10px 0px"
							type="text"
							md-width="100%"
							lg-width="100%"
							name="designation"
							required={true}
							value={jsonData.designation}
							onChange={handleInputChange}
						/>
						<Input
							placeholder="Message"
							width="66.66%"
							height="20%"
							margin="0px 4px 10px 0px"
							type="text"
							md-width="100%"
							lg-width="100%"
							name="message"
							required={true}
							value={jsonData.message}
							onChange={handleInputChange}
						/>
						<Button width="66.66%" lg-width="100%" onClick={handleSubmitForm}>
							Submit
						</Button>
					</Formspree>
				</Box>
				<Box>
					<Box margin="0px 0px 36px 0px" xl-margin="0px 0px 24px 0px">
						<Text
							font="--base"
							letter-spacing="1px"
							margin="0px 0px 10px 0px"
							quarkly-title="Title"
							color="--dark"
							text-transform="uppercase"
							xl-margin="0px 0px 4px 0px"
						>
							Editorial Office
						</Text>
						<Text
							opacity="0.6"
							quarkly-title="Description"
							color="--dark"
							font="--base"
							margin="0px 0px 0px 0px"
							display="inline-block"
						>
							Editor-In-Chief
							<br />
							Dr. Yogesh A. Sontakke MBBS MD
							<br />
							Additional Professor
							(Department of Anatomy)
							<br />
							National Journal of Society of Medical Anatomists
							<br />
							<br />
							A2, 1st floor, Chinmayam Apartment, 1st Theresa ST. Louis Reddiyar Garden, Reddiyarpalayam
							<br />
							Puducherry
							<br />
							PIN : 605006
						</Text>
					</Box>
					<Box quarkly-title="Contact" xl-margin="0px 0px 24px 0px" margin="0px 0px 36px 0px">
						<Text
							color="--dark"
							text-transform="uppercase"
							xl-margin="0px 0px 4px 0px"
							font="--base"
							letter-spacing="1px"
							margin="0px 0px 10px 0px"
							quarkly-title="Title"
						>
							Contact us
						</Text>
						<Link
							color="--dark"
							quarkly-title="Email"
							white-space="normal"
							hyphens="manual"
							font="--base"
							text-decoration-line="initial"
							hover-text-decoration-line="underline"
							opacity="0.6"
							word-break="normal"
							target="_blank"
							transition="opacity .15s ease 0s"
							text-indent="0"
							text-overflow="clip"
							hover-opacity="0.6"
							href="mailto:hello@company.com"
							overflow-wrap="normal"
						>
							editorinchiefsoma@gmail.com
						</Link>
					</Box>
					<Box margin="0px 0px 36px 0px" quarkly-title="Working Hours" xl-margin="0px 0px 24px 0px" opacity={1}>
						<Text
							font="--base"
							letter-spacing="1px"
							margin="0px 0px 10px 0px"
							quarkly-title="Title"
							color="--dark"
							text-transform="uppercase"
							xl-margin="0px 0px 4px 0px"
						>
							Call us
						</Text>
						<Link
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
							opacity="0.6"
							transition="opacity .15s ease 0s"
							target="_blank"
							display="block"
							text-decoration-line="initial"
							overflow-wrap="normal"
							word-break="normal"
							text-indent="0"
							hover-opacity=".5"
							href="tel:+1(234)567-89-00"
							quarkly-title="Phone"
							color="--dark"
							font="--base"
						>
							(+91) 9080085040
						</Link>
					</Box>
					<Text
						font="--lead"
						text-align="center"
						padding="14px 24px 14px 24px"
						margin="0px 0px 0px 0px"
						xl-font="--base"
						md-display="block"
						display="inline-block"
						quarkly-title="Description"
						color="--dark"
						background="--color-lightD1"
					>
						We're always happy to make valuable new contacts!
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			justify-content="center"
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Footer"
		>
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						src={SOMALogo}
						width="28px"
						height="28px"
						srcSet=""
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						text-align="left"
						margin="1px 0px 0px 10px"
						white-space="nowrap"
						opacity="0.6"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
						href="https://www.societyofmedicalanatomists.com/"
						font="--base"
						color="--dark"
					>
						SOMA
					</Link>
				</Box>
				<SocialMedia facebook="https://www.facebook.com/NJSOMA/" twitter="https://twitter.com/njsoma" youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw" justify-content="flex-end">
					<Override slot="link" background="none" border-radius="50%" />
					<Override slot="icon" color="--dark" />
				</SocialMedia>
			</Box>
		</Section>
	</Theme>;
});